import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

import { User } from '../../../core/models/class/user';
import { MunicipalityService } from '../../../services/municipality.service';
import { NotificationService } from '../../../services/notification.service';
import {
  getItem,
  getStates,
  mapMunicipalities,
  navigateNotification,
  notificationsNormalized,
  removeItem,
  StorageItem,
} from '../../../core';
import { Municipality } from 'src/app/core/models/class/municipality';
import { Notification } from 'src/app/core/models/class/notification';
import { MainFeature } from 'src/app/core/models/class/GenericListFeature';
import { UserService } from 'src/app/core/services/user.service';
import { MUNICIPALITY_ROUTE_PARAM } from 'src/app/app-routing.module';
import { features } from 'src/app/core/config';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent extends MainFeature implements OnInit {
  private municipalities = new Array<Municipality>();
  private userMunicipalities: Array<Municipality> = new Array<Municipality>();
  private navbar: JqueryNavBar = new JqueryNavBar();

  states = new Set<string>();
  mappedMunicipalities: Map<string, Array<Municipality>> = null;
  expanded = new Array<{ state: string; expanded: boolean }>();
  activeMunis = new Array<Municipality>();
  municipalityName: string;
  loggedInUser$: Observable<User> = this._userFacade.loggedInUser$;
  isLoadingUser$: Observable<boolean> = this._userFacade.isLoading$;
  isLoadingMuni$: Observable<boolean> = this._munisFacade.isLoading$;
  selectedMuni$: Observable<Municipality> = this._munisFacade.selectedMuni$;
  selectedMunicipalityId: number;
  userMunicipalitiesFiltred: Array<Municipality> = new Array<Municipality>();
  expandedMenu = false;
  noActiveMunicipality = false;
  notReadNotifsLength;
  searchText = '';
  expandedState = '';
  urlParams;
  urlHome;
  statusNotifications: boolean;
  showAnimation;

  // oldRoute = '';
  notifications: Array<Notification> = new Array<Notification>();
  constructor(
    public route: Router,
    public activeRoute: ActivatedRoute,
    public utilsService: MunicipalityService,
    public userService: UserService,
    public _toastLuncher: ToastLuncherService,
    public _munisFacade: MunisFacade,
    public _uiFacade: UiFacade,
    public _userFacade: UserFacade,
    public notificationService: NotificationService,
  ) {
    super(_userFacade, _toastLuncher);
    this.activeRoute.params.subscribe(params => {
      const munisNameFromRoute = params[MUNICIPALITY_ROUTE_PARAM];
      this._munisFacade.setMuniFromName(munisNameFromRoute);
      if (this._userFacade.isAuthenticated()) {
        this.showGreetingsMessage();
        this._userFacade.loadUser();
      }
      this.loggedInUser$.subscribe(() => this.setUser());

      this.route.events.subscribe(val => {
        if (val instanceof NavigationEnd) {
          // this.oldRoute = this.urlHome;
          this.urlHome = this.activeRoute.snapshot['_routerState'].url.split('/');
          if (this.urlHome.length === 2) {
            this.navbar.closeSidenav();
          } else {
            this.navbar.openSidenav();
          }
        }
      });
    });
  }

  ngOnInit() {
    this._toastLuncher.showMobileApp();
    this.urlHome = this.activeRoute.snapshot['_routerState'].url.split('/');
    this.setState();
    window.addEventListener('load', event => {
      if (this.urlHome.length === 2) {
        document.getElementById('toggle-sidenav')?.click();
      }
    });
  }

  readAllNotifications() {
    this.notifications.forEach(item => (item.is_read = true));
    this.notReadNotifsLength = 0;
    this.notificationService.markAllAsRead().subscribe();
    this.showAnimation = false;
  }

  getInfo() {
    const url = this.activeRoute.snapshot['_routerState'].url;
    let title = null;
    let description = '';
    const currentFeature = features.find(f => {
      if (Array.isArray(f.route)) {
        return f.route.some(r => url.includes(r));
      } else {
        return url.includes(f.route);
      }
    });

    if (currentFeature !== undefined) {
      title = currentFeature.documentName;
      description = currentFeature.longDescription;
    }
    if (title !== null) {
      Swal.fire(title, description, 'info');
    }
  }

  logout() {
    this._userFacade.logout();
  }

  expand() {
    if (this.expandedMenu) {
      this.expandedMenu = false;
    } else {
      this.expandedMenu = true;
    }
  }

  private setState() {
    this._munisFacade.selectedMuni$.subscribe(muni => {
      if (muni) {
        this.municipalityName = muni.route_name;
        this.selectedMunicipalityId = muni.id;
      }
    });

    this._munisFacade.munisList$.subscribe(munis => {
      if (munis) {
        this.activeMunis = munis.filter(muni => muni.is_active);
        this.municipalities = munis;
        this.states = getStates(this.municipalities);
        this.mappedMunicipalities = mapMunicipalities(this.municipalities);
        if (this._userFacade.isAuthenticated()) {
          this.getNotifications();

          this.setUser();
        }
        this.expanded = [];
        Array.from(this.mappedMunicipalities.keys()).forEach(item => {
          if (item) this.expanded.push({ state: item, expanded: false });
        });
      }
    });
  }

  private setUser() {
    this._userFacade.loggedInUser$.subscribe(data => {
      if (data?.municipalities) {
        this.userMunicipalities = this.municipalities.filter(item => {
          return data.municipalities.includes(item.id);
        });
        this.userMunicipalitiesFiltred = this.userMunicipalities;
        this.activeMunis = this.activeMunis.filter(item => !data.municipalities.includes(item.id));
      }
    });
  }

  followMun(municipality: Municipality, e) {
    e.stopPropagation();
    this.searchText = '';
    this.utilsService.followMunicipality(municipality.id.toString(), municipality.is_selected).subscribe(
      data => {
        this.userMunicipalities.push(municipality);
        this.userService.editUser({ preferred_municipality_id: municipality.id }).subscribe(data => {
          this.searchMunicipality();
        });
        this.municipalities.forEach(item => {
          if (item.id === municipality.id) {
            item.is_selected = !item.is_selected;
          }
        });
      },
      error => {
        throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
      },
    );
  }

  unfollowMun(municipality: Municipality, e) {
    e.stopPropagation();
    this.utilsService.followMunicipality(municipality.id.toString(), municipality.is_selected).subscribe(
      data => {
        this.userMunicipalities = this.userMunicipalities.filter(item => item.id !== municipality.id);
        if (municipality.id === this.selectedMunicipalityId) {
          this.userService.editUser({
            preferred_municipality_id: this.userMunicipalities[0].id,
          });
        }
        this.searchMunicipality();
        this.municipalities.forEach(item => {
          if (item.id === municipality.id) {
            item.is_selected = !item.is_selected;
          }
        });
      },
      error => {
        throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
      },
    );
  }

  changeFollowingState(municipality: Municipality) {
    if (municipality.is_selected && this.userMunicipalities.length < 2) {
      throw new Error('أنت تتابع بلدية واحدة، وبالتالي لا يمكن حذفها!');
    }
    if (!municipality.is_selected) {
      Swal.fire({
        title: municipality.name,
        text: 'هل تريد متابعة هذه البلدية ام الذهاب الى صفحتها فقط؟',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'متابعة البلدية',
        cancelButtonText: 'الذهاب للصفحة',
      }).then(result => {
        if (result.value) {
          this.utilsService.followMunicipality(municipality.id.toString(), municipality.is_selected).subscribe(
            data => {
              this.userMunicipalities.push(municipality);
              this.userService.editUser({ preferred_municipality_id: municipality.id }).subscribe(data => {
                this.searchMunicipality();
              });
              this.municipalities.forEach(item => {
                if (item.id === municipality.id) {
                  item.is_selected = !item.is_selected;
                }
              });
              this._toastLuncher.success({
                showConfirmButton: false,
                text: 'تمت العملية بنجاح!',
              });
              setTimeout(() => {
                $('#municipality_selection_modal').modal('show');
                Swal.close();
              }, 1000);
            },
            error => {
              throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
            },
          );
        } else {
          this.route.navigateByUrl(`/${municipality.route_name}`);
        }
      });
    } else {
      Swal.fire({
        title: municipality.name,
        text: 'هل تريد التوقف عن متابعة هذه البلدية ام الذهاب الى صفحتها فقط؟',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'التوقف عن المتابعة',
        cancelButtonText: 'الذهاب للصفحة',
      }).then(result => {
        if (result.value) {
          this.utilsService.followMunicipality(municipality.id.toString(), municipality.is_selected).subscribe(
            data => {
              this.userMunicipalities = this.userMunicipalities.filter(item => item.id !== municipality.id);
              if (municipality.id === this.selectedMunicipalityId) {
                this.userService
                  .editUser({
                    preferred_municipality_id: this.userMunicipalities[0].id,
                  })
                  .subscribe(data => {
                    location.reload();
                  });
              }
              this.setState();
              this.searchMunicipality();
              this.municipalities.forEach(item => {
                if (item.id === municipality.id) {
                  item.is_selected = !item.is_selected;
                }
              });
              this._toastLuncher.success({
                showConfirmButton: false,
                text: 'تمت العملية بنجاح!',
              });
              setTimeout(() => {
                $('#municipality_selection_modal').modal('show');
                Swal.close();
              }, 1000);
            },
            error => {
              throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
            },
          );
        } else {
          this.route.navigateByUrl(`/${municipality.route_name}`);
        }
      });
    }
  }

  selectMunicipality(municipality: Municipality) {
    if (municipality.id === this.selectedMunicipalityId) {
      return;
    }
    if (this.userMunicipalities.includes(municipality)) return this.changeMuni(municipality);
    Swal.fire({
      title: municipality.name,
      text: 'هل تريد تغيير البلدية إلى ' + municipality.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا',
    }).then(result => {
      if (result.value) this.changeMuni(municipality);
    });
  }

  changeMuni(municipality: Municipality) {
    this._munisFacade.selectMuni(municipality);
    const url = this.activeRoute.snapshot['_routerState'].url.split('/');

    if (url.length === 2) {
      this.route.navigateByUrl(`/${municipality.route_name}`);
    } else if (url.length === 4) {
      this.route.navigateByUrl(`/${municipality.route_name}/${url[url.length - 2]}`);
    } else {
      this.route.navigateByUrl(`/${municipality.route_name}/${url[url.length - 1]}`);
    }
  }

  private getNotifications() {
    this.notificationService.getNotifications().subscribe(data => {
      if (data) {
        this.notifications = notificationsNormalized(data);

        this.notReadNotifsLength = this.notifications.filter(item => item.is_read === false).length;
      }
    });
    this.showAnimation = this.notReadNotifsLength > 0 ? true : false;
  }

  markAsRead(index: number, redirect: string, routeMunicipality: String) {
    this.notificationService.markAsRead(this.notifications[index].id.toString()).subscribe(data => {
      if (!this.notifications[index].is_read) {
        this.notReadNotifsLength--;
        this.notifications[index].is_read = true;
      }
      if (typeof redirect === 'string') this.route.navigate([routeMunicipality + navigateNotification(redirect)]);
    });
  }

  searchMunicipality() {
    // search munis in a city
    if (this.expandedState) {
      // expandedState === city name
      this.userMunicipalitiesFiltred = this.userMunicipalities.filter(item => {
        return (
          (item.name_fr.toLowerCase().search(this.searchText) !== -1 ||
            item.name.toLowerCase().search(this.searchText) !== -1) &&
          item.city === this.expandedState
        );
      });
      this.activeMunis = this.mappedMunicipalities.get(this.expandedState).filter(item => {
        return (
          (item.name_fr.toLowerCase().search(this.searchText) !== -1 ||
            item.name.toLowerCase().search(this.searchText) !== -1) &&
          item.is_active
        );
      });
      // search all munis
    } else {
      this.activeMunis = this.municipalities.filter(item => {
        return (
          (item.name_fr.toLowerCase().search(this.searchText) !== -1 ||
            item.name.toLowerCase().search(this.searchText) !== -1) &&
          item.is_active
        );
      });
      this.userMunicipalitiesFiltred = this.userMunicipalities.filter(item => {
        return (
          item.name_fr.toLowerCase().search(this.searchText) !== -1 ||
          item.name.toLowerCase().search(this.searchText) !== -1
        );
      });
    }

    this.activeMunis = this.activeMunis.filter(muni => !this.userMunicipalitiesFiltred.includes(muni));
  }

  @HostListener('window:resize') windwosResize() {
    const windowsWidth = window.innerWidth;
    if (windowsWidth > 768) {
      this.navbar.closeSidenav();
    }
  }

  showGreetingsMessage(hasActiveMunicipality = false) {
    /**
     *     if (hasActiveMunicipality)
          return Swal.fire(
            'مرحبا بيك في منصة البلدية الرقمية',
            'للأسف بلديتك لم تسجل إلى الآن حساب تواصلي في المنصة. لكن, يمكنك التفاعل مع بقية البلديات المفعلة في المنصة و الاستفادة من الخدمات المدرجة بيها. قم باختيار البلديات التي تهتم بمتابعة أخبارها. يمكنك تغيير اختيارك في كل وقت.',
            'question',
          );
    */
    if (getItem(StorageItem.first_login) == 'true') {
      Swal.fire(
        'مرحبا بيك في منصة البلدية الرقمية !',
        'يمكنك التفاعل مع بلديتك و مع كل البلديات المفعلة في المنصة و الاستفادة من الخدمات المدرجة بيها. يمكنك تعديل قائمة البلديات اللي تتابعها أو التغيير من بلدية إلى أخرى من خلال الضغط على اسم و شعار البلدية في أعلى قائمة الخيارات.',
        'question',
      );
      return removeItem(StorageItem.first_login);
    }
    if (parseInt(getItem(StorageItem.latest_residence_update_date)) > 6) {
      Swal.fire('أهلا بكم من جديد', 'يرجى تحديث مكان إقامتك', 'question').then(() => {
        this.route.navigate(['profile'], { relativeTo: this.activeRoute });
        return removeItem(StorageItem.latest_residence_update_date);
      });
    }
  }
}

class JqueryNavBar {
  openHomeSidenav() {
    const item = document.getElementById('toggle-sidenav');
    item?.click();
  }

  openSidenav() {
    const elem = document.getElementById('toggle-sidenav')?.classList;
    if (!elem?.contains('is-active')) {
      this.openHomeSidenav();
    }
  }

  closeSidenav() {
    const elem = document.getElementById('toggle-sidenav')?.classList;
    if (elem?.contains('is-active')) {
      this.openHomeSidenav();
    }
  }

  toggleHomeNavbar() {
    document.getElementById('toggle-sidenav').click();
    // this.urlHome = this.urlHome === 'home' ? 'hoverHome' : 'home';
    // var st = document.getElementById('navHome').style.display;
    // if (st === '') {
    //   st = 'none';
    // }
    //  (document.getElementById('navHome').style.position);
    // document.getElementById('navHome').style.display = st === 'none' ? 'block' : 'none';
  }
}
